import {parseTemplate} from 'url-template'

require('whatwg-fetch')

const BASE_URI = "https://ws.postcoder.com"
const AUTOCOMPLETE_URL_TEMPLATE = parseTemplate(`${BASE_URI}/pcw/autocomplete/find?query={query}&enablefacets=false&country={countryCode}&apikey={key}`)
const RESOLVE_URL_TEMPLATE      = parseTemplate(`${BASE_URI}/pcw/autocomplete/retrieve/?id={addressId}&query={query}&country={countryCode}&apikey={key}`)

function fetchSuggestions(query, country) {
  return fetch(autocompleteApiUrl(query, country)).then(async (response) => {
    if(!response.ok) {
      // Postcoder drops to text when not a 200
      throw await response.text().then((str) => `Postcoder error ${response.status}: ${str}`)
    }

    return response.json()
  });
}

function fetchResolvedAddress(addressId, country) {
  return fetch(resolveAddressApiUrl(addressId, country)).then(response => response.json());
}

// Map a raw getAddress suggestions response into a common AddressLookup format
function mapSuggestions(response) {
  return response.map((s) => {
    return {
      id:          s.id,
      displayName: `${s.summaryline}, ${s.locationsummary}`
    }
  })
}

// Map a raw postcoder address response into a common AddressLookup format
function mapAddress(response) {
  const address = response[0]
  const mapped = {
    line1:      address.addressline1 || `${address.premise} ${address.street}`,
    line2:      address.addressline2 || '',
    townOrCity: address.posttown || '',
    county:     address.county || '',
    country:    address.country || '',
    postcode:   address.postcode || '',
  }

  mapped.formatted = address.summaryline.split(', ').join("\n")
  return mapped
}

// private
function autocompleteApiUrl(query, countryCode) {
  return AUTOCOMPLETE_URL_TEMPLATE.expand({
    query: query,
    countryCode: countryCode,
    key: process.env.POSTCODER_API_KEY
  })
}

function resolveAddressApiUrl(addressId, countryCode) {
  return RESOLVE_URL_TEMPLATE.expand({
    addressId: addressId,
    countryCode: countryCode,
    key: process.env.POSTCODER_API_KEY
  });
}

export { fetchSuggestions, fetchResolvedAddress, mapSuggestions, mapAddress, BASE_URI }
