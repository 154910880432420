import * as getAddress from './providers/get_address.js'
import * as postcoder  from './providers/postcoder.js'

// Gives a uniform interface over multiple two-phase HTTP API address providers, where
// the phases are `fetchSuggestions` and `fetchResolvedAddress`.
export default class AddressLookup {
  #country
  #provider

  // Supported countries and the strategic providers to which they map
  PROVIDERS_BY_COUNTRY = {
    'UK': getAddress,
    'NI': getAddress,
    'IE':  postcoder
  }

  constructor(country) {
    this.#provider = this.PROVIDERS_BY_COUNTRY[country]
    this.#country = country
    if(!this.#provider) {
      throw(`Provider for country '${country}' not found`)
    }
  }

  async fetchSuggestions(query) {
    const raw = await this.#provider.fetchSuggestions(query, this.#country);
    return this.#provider.mapSuggestions(raw)
  }

  async fetchResolvedAddress(addressId) {
    const raw = await this.#provider.fetchResolvedAddress(addressId, this.#country)
    return this.#provider.mapAddress(raw)
  }

  get BASE_URI() {
    return this.#provider.BASE_URI
  }
}
