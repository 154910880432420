import {parseTemplate} from 'url-template'
require('whatwg-fetch')

const BASE_URI = "https://api.getaddress.io"
const AUTOCOMPLETE_URL_TEMPLATE = parseTemplate(`${BASE_URI}/autocomplete/{query}?api-key={key}`)
const RESOLVE_URL_TEMPLATE      = parseTemplate(`${BASE_URI}/get/{addressId}?api-key={key}`)
const SUGGESTION_TEMPLATE       = "{formatted_address} {outcode}"
const TOP                       = 10

function fetchSuggestions(query, country) {
  const filters = {}
  if(country === 'NI') {
    filters.country = 'Northern Ireland'
  }

  return fetch(autocompleteApiUrl(query), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      filter: filters,
      top: TOP,
      all: true,
      template: SUGGESTION_TEMPLATE
    })
  }).then((response) => response.json())
}

// Map a raw getAddress suggestions response into a common AddressLookup format
function mapSuggestions(response) {
  return response.suggestions.map((s) => {
    return {
      id:          s.id,
      displayName: s.address
    }
  })
}

// Map a raw getAddress address response into a common AddressLookup format
function mapAddress(response) {
  return {
    line1:      response.line_1,
    line2:      response.line_2,
    townOrCity: response.town_or_city,
    county:     response.county,
    country:    response.country,
    postcode:   response.postcode,
    formatted:  format(response)
  }
}

function format(address) {
  return [
    address.line_2,
    address.line_1,
    address.town_or_city,
    address.county,
    address.postcode
  ].filter((line) => line != '').join("\n")
}


function fetchResolvedAddress(addressId) {
  return fetch(resolveAddressApiUrl(addressId)).then(response => response.json());
}

// private
function autocompleteApiUrl(query) {
  return AUTOCOMPLETE_URL_TEMPLATE.expand({
    query: query,
    key: process.env.GETADDRESS_API_KEY
  })
}

function resolveAddressApiUrl(addressId) {
  return RESOLVE_URL_TEMPLATE.expand({
    addressId: addressId,
    key: process.env.GETADDRESS_API_KEY
  });
}

export { fetchSuggestions, fetchResolvedAddress, mapSuggestions, mapAddress, BASE_URI }
