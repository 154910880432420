// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// ActiveStorage.start()

import {initAll} from 'govuk-frontend'
import './controllers'

if(process.env.ENABLE_LIVE_RELOADING === "1") require('./esbuild/live_reload_client')

document.addEventListener("DOMContentLoaded", function(){
  document.body.className += ' js-enabled' + ('noModule' in HTMLScriptElement.prototype ? ' govuk-frontend-supported' : '');
  initAll()
})
