import accessibleAutocomplete from 'accessible-autocomplete'
import {parseTemplate} from 'url-template'

import {Controller} from "@hotwired/stimulus"
import {showHide} from "../govuk/visibility"
import {debounce} from "./debounce"

// Used to decorate a text input for providing a lookup with autocompleting
// behaviour.
export default class extends Controller {
  #debouncing = false

  LOOKUP_SEARCH_PATH_TEMPLATE = parseTemplate("/lookups/{type}.json?query={query}")

  static targets = [
    'id',               // The hidden field
    'textfield',         // The input that we wrap
    'autocompleteRoot', // An empty element the autocomplete is rendered in
    'suggestion',       // An optional <template> tag to render a suggestion
    'visibleSearching', // Items that are visible when searching
    'visibleManual'     // Items that are not
  ]
  static values = {
    type: String,       // The type of the lookup, plural, e.g. 'employers'
    noResults: String,  // The translation for noResults
    searching: {
      type: String,
      default: ''
    },  // The translation for 'searching'
    minLength: {        // The minimum number of characters before a search
      type: Number,
      default: 3
    },
    debounceMs: {       // The number of ms to wait with no keystrokes before searching
      type: Number,
      default: 200
    }
  }

  // configure and attach the autocomplete to the designated input.
  connect() {
    this._id = this.textfieldTarget.id

    this.handleQueryDebounced = debounce(async (query, populateResults) => {
      await this.handleQuery(query, populateResults);
    }, this.debounceMsValue);

    this.debouncedSource = async (query, populateResults) => {
      this.#debouncing = true;
      try {
        await this.handleQueryDebounced(query, populateResults);
      } finally {
        this.#debouncing = false;
      }
    }

    accessibleAutocomplete({
      element: this.autocompleteRootTarget,
      id: this.textfieldTarget.id, // To match it to the existing <label>.
      defaultValue: this.textfieldTarget.value,
      source: this.debouncedSource.bind(this),
      onConfirm: this.lookupSelected.bind(this),
      minLength: this.minLengthValue,
      tNoResults: () => this.#debouncing ? this.searchingValue : this.noResultsValue,
      templates: {
        suggestion: this.renderSuggestion.bind(this)
      }
    })
    this.textfieldTarget.id = null
    this.autoCompleteInput.addEventListener('input', () => this.clearLookupId())
    this.searching = this.lookupIdBlank() && this.lookupNameBlank() && this.noErrorMessages()
  }

  lookupIdBlank() {
    return !this.idTarget.value;
  }

  lookupNameBlank() {
    return !this.textfieldTarget.value;
  }

  noErrorMessages() {
    return this.element.querySelectorAll('.govuk-error-message').length === 0
  }

  toggleSearching(event) {
    event.preventDefault()
    this.searching = !this.searching
  }

  // Are we currently showing the JS autocomplete?
  get searching() {
    return this._searching
  }

  // Set state of autocomplete; if searching, show the JS autocomplete, if not
  // show the fallback
  set searching(value) {
    this._searching = value
    this._searching ? this.showSearch() : this.hideSearch()
  }

  // hide the search autocomplete, show the manual text field
  hideSearch() {
    this.textfieldTarget.id = this._id
    this.autoCompleteInput.id = null

    this.textfieldTarget.readOnly = !this.lookupIdBlank()
    this.textfieldTarget.value = this.autoCompleteInput.value

    this.setVisibilities()

    this.textfieldTarget.focus()
  }

  // show the search autocomplete, hide the manual text field
  showSearch() {
    // Reset the input
    this.autoCompleteInput.value = ''
    this.autoCompleteInput.id = this._id
    this.textfieldTarget.id = null

    this.clearLookupId()

    this.setVisibilities()
  }

  get submitButton() {
    return this.textfieldTarget.form.querySelector('input[type=submit]')
  }

  clearLookupId() {
    return this.idTarget.value = '';
  }

  get autoCompleteInput() {
    if(this._autocompleteInput === undefined) {
      this._autocompleteInput = this.element.querySelector('.autocomplete__input')
    }
    return this._autocompleteInput
  }

  // function that handles user search queries and calls the `populateResults`
  // callback with the results of the search
  async handleQuery(query, populateResults) {
    this._latestSearchResults = await this.lookupSearch(query)
    // We are obliged to call .toString() here as AAC will call .toLowerCase() on it without checking.
    // This means we also have to .toString in findLookup
    populateResults(this._latestSearchResults.map(suggestion => suggestion.id.toString()))
  }

  findLookup(id) {
    return this._latestSearchResults.find((lookup) => lookup.id.toString() === id)
  }

  renderSuggestion(id) {
    if(!this._latestSearchResults) return; // the suggestion function is called during setup. No results. Bail!

    const lookup = this.findLookup(id)

    return this.hasSuggestionTarget ? this.renderTemplate(lookup) : lookup.name;
  }

  renderTemplate(lookup) {
    const template = this.suggestionTarget.innerHTML

    // Mini templating engine. Replace {{name}}, {{address1}} etc as if a tiny Mustache/Handlebars
    return template.replace(/\{\{(\w+)}}/g, (match, key) => {
      return lookup[key] || '';
    });
  }

// returns an array of lookups that match `query`
  lookupSearch(query) {
    const request_path = this.LOOKUP_SEARCH_PATH_TEMPLATE.expand({query: query, type: this.typeValue})
    return fetch(request_path).then((response) => response.json());
  }

  // callback that is called when the user selects an option. Sets the value of
  // hidden<attr>Id to match the selected lookup.
  lookupSelected(id) {
    if(id === undefined || this._latestSearchResults === undefined) {
      return
    }

    const matchingResult = this.findLookup(id)
    this.searching = false
    this.idTarget.value = matchingResult.id
    this.textfieldTarget.value = matchingResult.name
    this.textfieldTarget.readOnly = true
    this.removeValidationErrors()
  }

  removeValidationErrors() {
    this.element.querySelector('.govuk-error-message')?.remove()
  }

  setVisibilities() {
    this.visibleSearchingTargets.forEach(el => showHide(el, this.searching))
    this.visibleManualTargets.forEach(el => showHide(el, !this.searching))

    // Not technically part of the controller, so we can't decorate it with visibleManual. Bit hacky, possibly.
    // Maybe we could allow the generator to generate
    // `f.next_button data: { 'lookup-autocomplete-target': 'visibleManual' }`
    showHide(this.submitButton, !this.searching)
  }
}
